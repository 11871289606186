/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://6gbi6h1slg.execute-api.us-west-1.amazonaws.com/dev",
            "region": "us-west-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-1:41de154d-ed2b-424a-a77e-25ae54d8093e",
    "aws_cognito_region": "us-west-1",
    "aws_user_pools_id": "us-west-1_7vVidx8Tw",
    "aws_user_pools_web_client_id": "4cffaisnallbk0lh7775h9u2n6",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 6,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "vhshots-storage-4c3a7943-admin02206-dev",
    "aws_user_files_s3_bucket_region": "us-west-1"
};


export default awsmobile;
